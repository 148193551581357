var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBL0003089" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable && !_vm.disabled
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "LBLREMOVE",
                                    icon: "delete_forever",
                                  },
                                  on: { btnClicked: _vm.removeData },
                                })
                              : _vm._e(),
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.mappingType,
                                    label: "LBLSAVE",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveData,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              label: "LBL0003085",
                              name: "jointInspectionName",
                            },
                            model: {
                              value: _vm.data.jointInspectionName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "jointInspectionName", $$v)
                              },
                              expression: "data.jointInspectionName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              range: "",
                              label: "LBL0003086",
                              name: "data.jointDts",
                            },
                            model: {
                              value: _vm.data.jointDts,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "jointDts", $$v)
                              },
                              expression: "data.jointDts",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              type: "edit",
                              name: "plantCd",
                            },
                            on: { datachange: _vm.datachange },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              rows: 1,
                              label: "LBL0003068",
                              name: "remark",
                            },
                            model: {
                              value: _vm.data.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "remark", $$v)
                              },
                              expression: "data.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c(
                  "c-table",
                  {
                    ref: "inGrid",
                    attrs: {
                      title: "LBL0003069",
                      editable: _vm.editable,
                      columns: _vm.inGrid.columns,
                      gridHeight: _vm.gridHeight,
                      data: _vm.data.innerList,
                      filtering: false,
                      columnSetting: false,
                      usePaging: false,
                      rowKey: "userId",
                      selection: "multiple",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "LBLADD",
                                    icon: "add",
                                  },
                                  on: { btnClicked: _vm.addInner },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            _vm.data.innerList &&
                            _vm.data.innerList.length > 0
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "LBLEXCEPT",
                                    icon: "remove",
                                  },
                                  on: { btnClicked: _vm.removeInner },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c(
                  "c-table",
                  {
                    ref: "vendorGrid",
                    attrs: {
                      title: "LBL0003090",
                      editable: _vm.editable,
                      columns: _vm.vendorGrid.columns,
                      gridHeight: _vm.gridHeight,
                      data: _vm.data.vendorList,
                      filtering: false,
                      columnSetting: false,
                      usePaging: false,
                      rowKey: "vendorCd",
                      selection: "multiple",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "LBLADD",
                                    icon: "add",
                                  },
                                  on: { btnClicked: _vm.addVendor },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            _vm.data.vendorList &&
                            _vm.data.vendorList.length > 0
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "LBLEXCEPT",
                                    icon: "remove",
                                  },
                                  on: { btnClicked: _vm.removeVendor },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c(
                  "c-table",
                  {
                    ref: "outGrid",
                    attrs: {
                      title: "LBL0003091",
                      editable: _vm.editable,
                      columns: _vm.outGrid.columns,
                      gridHeight: _vm.gridHeight,
                      data: _vm.data.outerList,
                      filtering: false,
                      columnSetting: false,
                      usePaging: false,
                      rowKey: "saiJointOuterUserId",
                      selection: "multiple",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "LBLADD",
                                    icon: "add",
                                  },
                                  on: { btnClicked: _vm.addOuter },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            _vm.data.outerList &&
                            _vm.data.outerList.length > 0
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "LBLEXCEPT",
                                    icon: "remove",
                                  },
                                  on: { btnClicked: _vm.removeOuter },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-table",
                  {
                    ref: "itemGrid",
                    attrs: {
                      title: "LBL0003092",
                      editable: _vm.editable,
                      columns: _vm.itemGrid.columns,
                      gridHeight: _vm.gridHeight2,
                      data: _vm.data.itemResultList,
                      filtering: false,
                      columnSetting: false,
                      usePaging: false,
                      rowKey: "jointInspectionResultId",
                      selection: "multiple",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "customArea",
                        fn: function ({ props, col }) {
                          return [
                            col.name === "customCol"
                              ? [
                                  _c(_vm.imprComponent, {
                                    tag: "component",
                                    attrs: {
                                      col: col,
                                      props: props,
                                      inputEditable: _vm.editable,
                                      isImmShow: true,
                                      requestContentsCols:
                                        _vm.requestContentsCols,
                                      tableKey: "jointInspectionResultId",
                                      ibmTaskTypeCd: "ITT0000165",
                                      ibmTaskUnderTypeCd: "ITTU000180",
                                    },
                                    on: { imprChange: _vm.imprChange },
                                  }),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable && !_vm.disabled
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "LBLADD",
                                    icon: "add",
                                  },
                                  on: { btnClicked: _vm.addResult },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            !_vm.disabled &&
                            this.data.itemResultList.length > 0
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "LBLEXCEPT",
                                    icon: "remove",
                                  },
                                  on: { btnClicked: _vm.removeResult },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 q-mt-md",
              },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo,
                    editable: _vm.editable,
                    label: "LBL0003093",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }